import { stringToElement } from "./StringToElement";

/**
 * Ajoute une boîte de chargement à la page si le statut est vrai et la supprime si le statut est
 * faux
 * @param {boolean} status - booléen
 */
export const loading = function (status: boolean, colorClass = "text-primary") {
    if (status) {
        document.body.dataset.loadingBox = "true";
        var loaderHtml = `<div class="es_loading-box d-flex position-fixed">
        <svg aria-hidden="true" class="spinner m-auto es_icon es_icon-spinner es_icon-64 ${colorClass}">
            <use xlink:href="#es_icon-spinner"></use>
        </svg>
    </div>`;
        document.body.appendChild(stringToElement(loaderHtml));
    } else {
        document.body.dataset.loadingBox = "false";
        if (document.querySelector('.es_loading-box')) {
            let loadingBox = document.querySelector('.es_loading-box');

            if (loadingBox) {
                loadingBox.remove();
            }
        }
    }
};