
/**
 * Interface représentant les positions tactiles et les distances parcourues lors d'un événement tactile.
 */
interface TouchPosition {
    /** Distance totale en X entre le début et la fin du toucher */
    distanceEndX?: number;
    /** Distance totale en Y entre le début et la fin du toucher */
    distanceEndY?: number;
    /** Distance parcourue en X lors du déplacement */
    distanceMoveX?: number;
    /** Distance parcourue en Y lors du déplacement */
    distanceMoveY?: number;
    /** Durée totale du toucher en millisecondes */
    duration?: number;
    /** Position X à la fin du toucher */
    touchEndX?: number;
    /** Position Y à la fin du toucher */
    touchEndY?: number;
    /** Position X lors du déplacement */
    touchMoveX?: number;
    /** Position Y lors du déplacement */
    touchMoveY?: number;
    /** Position X au début du toucher */
    touchStartX?: number;
    /** Position Y au début du toucher */
    touchStartY?: number;
}

/**
 * Récupère les positions tactiles et calcule les distances parcourues ainsi que la durée des événements tactiles.
 * 
 * @param touchStartEvent - L'événement de départ du toucher.
 * @param callbackTouchStart - Fonction de rappel pour l'événement de départ du toucher.
 * @param callbackTouchMove - Fonction de rappel pour l'événement de déplacement du toucher.
 * @param callbackTouchEnd - Fonction de rappel pour l'événement de fin du toucher.
 * @returns Un objet `TouchPosition` contenant les informations des positions et des distances parcourues.
 */
export function touch(
    touchStartEvent: TouchEvent,
    callbackTouchStart?: (obj: TouchPosition) => void,
    callbackTouchMove?: (obj: TouchPosition) => void,
    callbackTouchEnd?: (obj: TouchPosition) => void
): TouchPosition {
    const obj: TouchPosition = {};
    let startTime: number | undefined;
    let endTime: number | undefined;

    /**
     * Gère les événements tactiles et met à jour les positions et les distances dans `obj`.
     * 
     * @param e - L'événement tactile à traiter.
     */
    const handleTouchEvent = (e: TouchEvent): void => {
        if (!startTime) {
            obj.touchStartX = touchStartEvent.changedTouches[0].clientX;
            obj.touchStartY = touchStartEvent.changedTouches[0].clientY;
            startTime = new Date().getTime();
            if (callbackTouchStart) {
                callbackTouchStart(obj);
            }
        } else if (e.type === "touchmove") {
            obj.touchMoveX = e.changedTouches[0].clientX;
            obj.touchMoveY = e.changedTouches[0].clientY;
            obj.distanceMoveX = obj.touchMoveX - obj.touchStartX!;
            obj.distanceMoveY = obj.touchMoveY - obj.touchStartY!;
            endTime = new Date().getTime();
            obj.duration = endTime - startTime;
            if (callbackTouchMove) {
                callbackTouchMove(obj);
            }
        } else if (e.type === "touchend") {
            obj.touchEndX = e.changedTouches[0].clientX;
            obj.touchEndY = e.changedTouches[0].clientY;
            obj.distanceEndX = obj.touchEndX - obj.touchStartX!;
            obj.distanceEndY = obj.touchEndY - obj.touchStartY!;
            endTime = new Date().getTime();
            obj.duration = endTime - startTime;
            if (callbackTouchEnd) {
                callbackTouchEnd(obj);
            }
            document.removeEventListener('touchstart', handleTouchEvent);
            document.removeEventListener('touchmove', handleTouchEvent);
            document.removeEventListener('touchend', handleTouchEvent);
        }
    };

    document.addEventListener('touchstart', handleTouchEvent);
    document.addEventListener('touchmove', handleTouchEvent);
    document.addEventListener('touchend', handleTouchEvent);

    return obj;
}
