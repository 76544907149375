import { StoreEventListener } from "../es_tools/StoreListener";
import { EsCollapse } from "./es_collapse";

/**
 * Module `EsCollapseToggle` : Gestion du comportement sur les éléments possédant les attributs `[data-toggle="collapse"][data-target="#foo"]`.
 */
export class EsCollapseToggle {
    /**
     * Gestionnaire d'événements et d'interactions pour le composant `EsCollapseToggle`.
     */
    private static _storeEventListener: StoreEventListener = new StoreEventListener();

    private constructor() { }

    /**
     * Libère les ressources et supprime tous les écouteurs d'événements associés.
     * @remarks
     * Cette méthode doit être appelée lorsqu'on ne nécessite plus le module `EsCollapseToggle`.
     */
    public static dispose() {
        this._storeEventListener.removeAllEventListeners();
    }

    /**
     * Initialise le module `EsCollapseToggle` en écoutant les clics sur le document.
     * Active ou désactive le collapse en fonction des éléments HTML cliqués.
     * @returns Instance courante du module `EsCollapseToggle`.
     * @remarks
     * Cette méthode doit être appelée une seule fois au moment de l'initialisation.
     */
    public static init() {
        this._storeEventListener.addEventListener(document, 'click', (e) => {
            // Vérifie si l'événement provient d'un élément HTML
            if (e.target && (e.target instanceof HTMLElement || e.target instanceof HTMLAnchorElement)) {
                // Vérifie si l'élément a l'attribut 'data-toggle' avec la valeur 'collapse' et 'data-target' défini
                const controller = e.target.closest<HTMLElement>('[data-toggle="collapse"]');
                if (controller) {
                    if (controller instanceof HTMLAnchorElement) {
                        e.preventDefault();
                    }

                    const collapseElements = this.findCollapseElements(controller);

                    // Récupère ou crée une instance de `EsCollapse` associée à l'élément collapse
                    if (collapseElements.length) {
                        collapseElements.forEach(collapseElement => {
                            const collapseInstance = EsCollapse.getOrCreateInstance(collapseElement);
                            // Active/désactive le collapse
                            collapseInstance.toggle();

                        });
                    }
                }
            }
        });

        return this;
    }

    /**
     * Recherche les éléments collapse associés à l'élément déclencheur.
     * @param controller - L'élément HTML déclencheur.
     * @returns Une liste d'éléments collapse correspondants.
     */
    private static findCollapseElements(controller: HTMLElement | HTMLAnchorElement) {
        const target = controller.dataset.target || controller.getAttribute('href');
        if (target) {
            const collapseElements = document.querySelectorAll<HTMLElement>(target);
            return Array.from(collapseElements);
        }

        return [];
    }

};
