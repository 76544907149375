/** 
 * La classe `Cookie` fournit des méthodes pour manipuler les cookies du navigateur.
 */
export class Cookie {
    private constructor() { }

    /**
     * Vérifie si un cookie avec un nom spécifique existe.
     * 
     * @param cname - Le nom du cookie à vérifier.
     * @returns `true` si le cookie existe, sinon `false`.
     */
    public static check(cname: string) {
        var cookie = this.get(cname);
        if (cookie != "") {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Récupère la valeur d'un cookie par son nom.
     * 
     * @param cname - Le nom du cookie à récupérer.
     * @returns La valeur du cookie, ou une chaîne vide si le cookie n'existe pas.
     */
    public static get(cname: string) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    /**
     * Définit un cookie avec un nom, une valeur et une date d'expiration.
     * 
     * @param cname - Le nom du cookie.
     * @param cvalue - La valeur du cookie.
     * @param exdays - Le nombre de jours avant l'expiration du cookie.
     */
    public static set(cname: string, cvalue: any, exdays: number) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
}
