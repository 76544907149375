export interface DebouncedFunction {
  /** 
   * Exécute la fonction débouncée après le délai spécifié.
   * @param {...Parameters<T>} args - Les arguments à passer à la fonction débouncée.
   * @returns {Promise<ReturnType<T>>} Une promesse qui se résout avec le résultat de la fonction.
   */
  execute: () => Promise<void>;
  /** 
   * Annule l'exécution différée de la fonction débouncée.
   */
  cancel: () => void;
}

/**
 * Fonction de debounce générique qui retarde l'exécution d'une fonction jusqu'à ce qu'un délai soit écoulé depuis la dernière fois qu'elle a été invoquée.
 *
 * @template T Type de la fonction à débouncer.
 * @param {T} func - La fonction à débouncer.
 * @param {number} delay - Le délai en millisecondes à attendre avant d'exécuter la fonction.
 * @returns {object} Un objet contenant les méthodes `execute` et `cancel`.
 */
export const debounce = function <T extends (...args: any[]) => any>(
  func: T,
  delay: number
): DebouncedFunction {
  let timeoutId: any;

  const execute = function (...args: Parameters<T>): Promise<ReturnType<T>> {
    return new Promise<ReturnType<T>>((resolve) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        const result = func(...args);
        resolve(result);
      }, delay);
    });
  };

  const cancel = function (): void {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  };

  return {
    execute,
    cancel,
  };
};
