import { Sanitizer } from "../es_tools/Sanitizer";

/** Définition de l'interface pour l'élément icône. */
export interface Item {
    name: string;
    width: string;
    height: string;
}


/** Définition de l'interface pour la requête get. */
export interface GetRequest {
    name: string;
    size?: "16" | "24" | "32" | "48" | "64";
    transform?: 'flip-x' | 'flip-y' | 'rotate-90' | 'rotate-180' | 'rotate-270';
    title?: string;
}

/** Prend un tableau d'objets icône et renvoie une fonction qui prend une chaîne ou un objet et renvoie un élément `SVG` */
export class EsIcon {
    /** Liste des icônes */
    list: Item[] = [];

    /**
     * Un constructeur pour la classe `EsIcon`.
     * @param {Item[]} list - La liste des éléments à afficher dans la liste des icônes.
     */
    constructor(list: Item[]) {
        this.list = list;
    }


    /**
     * Prend un objet avec le nom de l'icône, la taille, la transformation et le titre, et renvoie un élément `SVG` avec l'icône
     * @param {GetRequest | string} req - Il s'agit de l'objet de requête transmis à la méthode
     * get. Il peut s'agir d'une chaîne ou d'un objet.
     * @returns Une fonction qui renvoie un élément `SVG`.
     */
    get(req: GetRequest | string) {
        let name: GetRequest['name'], size: GetRequest["size"], transform: GetRequest['transform'], title: GetRequest['title'];

        if (typeof req === "string") {
            name = req;
        } else {
            ({ name, size, transform, title } = req);
        }

        let icoData = this.list.find(icon => {

            return icon.name == name;
        });

        if (!icoData) {
            return null;
        }

        let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svg.setAttribute('class', `es_icon es_icon-${icoData.name}`);
        let use = document.createElementNS('http://www.w3.org/2000/svg', 'use');
        use.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `#es_icon-${icoData.name}`);
        svg.appendChild(use);

        if (size) {
            svg.classList.add(`es_icon-${size}`);
        }

        if (transform) {
            svg.classList.add(`es_icon-${transform}`);
        }

        if (title) {
            let titleElement = document.createElementNS('http://www.w3.org/2000/svg', 'title');
            titleElement.innerHTML = Sanitizer.sanitize(title);
            svg.insertAdjacentElement('afterbegin', titleElement);

        } else {
            svg.setAttribute('aria-hidden', 'true');
        }

        return svg;

    }

}