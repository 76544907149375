import { Sanitizer } from "./Sanitizer";

/**
 * Prend une chaîne de HTML et renvoie le premier élément de la chaîne
 * @param {string} html - La chaîne HTML à convertir en élément.
 * @returns Une fonction qui prend une chaîne et renvoie un HTMLElement.
 */
export const stringToElement = function <T = HTMLElement>(html: string) {
    var template = document.createElement('template');
    html = html.trim();
    template.innerHTML = Sanitizer.sanitize(html);
    return <T><unknown>template.content.firstChild;
}
