
/**
 * Représente une source d'observation qui peut être souscrite à des écouteurs.
 *
 * @template T - Le type de la valeur émise par la source d'observation.
 */
export class EsObservable<T> {
    private subscribers: ((value: T) => void)[] = [];
    private value: T;

    /**
     * Crée une nouvelle instance de `EsObservable`.
     *
     * @param {T} value - La valeur initiale de la source d'observation.
     */
    constructor(value: T);
    constructor(value: any) {
        this.value = value;
    }

    /**
     * Émet une nouvelle valeur à tous les écouteurs souscrits.
     *
     * @param {T} value - La nouvelle valeur à émettre.
     */
    next(value: T): void {
        this.value = value;
        this.notifySubscribers();
    }

    /**
     * Souscrit à la source d'observation.
     *
     * @param {Function} subscriber - La fonction de rappel appelée lorsqu'une nouvelle valeur est émise.
     * @returns {EsSubscription} L'abonnement associé à la souscription.
     */
    subscribe(subscriber: (value: T) => void): EsSubscription {
        this.subscribers.push(subscriber);
        // Retourne une instance de souscription
        return new EsSubscription(subscriber, this.subscribers);
    }

    /**
     * Notifie tous les écouteurs souscrits avec la nouvelle valeur.
     */
    private notifySubscribers(): void {
        for (const subscriber of this.subscribers) {
            subscriber(this.value);
        }
    }
}

/**
 * Représente un abonnement à une source d'observation.
 */
export class EsSubscription {
    #subscriber: (value: any) => void;
    #subscribersArray: ((value: any) => void)[];

    /**
     * Crée une nouvelle instance de `EsSubscription`.
     *
     * @param {Function} subscriber - La fonction de rappel associée à cet abonnement.
     * @param {Function[]} subscribersArray - Le tableau des fonctions de rappel.
     */
    constructor(subscriber: (value: any) => void, subscribersArray: ((value: any) => void)[]) {
        this.#subscriber = subscriber;
        this.#subscribersArray = subscribersArray;
    }

    /**
     * Annule l'abonnement à la source d'observation.
     */
    public unsubscribe(): void {
        const index = this.#subscribersArray.indexOf(this.#subscriber);
        if (index !== -1) {
            this.#subscribersArray.splice(index, 1);
        }
    }
}
