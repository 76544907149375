
/** Animations JS disponibles */
export type AnimationsJS = 'elastic' | 'quad' | 'linear' | 'circ' | 'back' | 'bounce';

/** Configuration d'une animation */
export interface AnimateConfig {
    animation?: AnimationsJS,
    draw: (progress: number) => void,
    mode?: "easeIn" | "easeOut" | "easeInOut",
    duration?: number,
    callback?: Function;
}

/** Functions d'animations */
export const timingFn = {
    elastic: function elastic(x: number, timeFraction: number) {
        return Math.pow(2, 10 * (timeFraction - 1)) * Math.cos(20 * Math.PI * x / 3 * timeFraction);
    }.bind(null, 1.5),
    quad: function quad(timeFraction: number) {
        return Math.pow(timeFraction, 2);
    },
    linear: function linear(timeFraction: number) {
        return timeFraction;
    },
    circ: function circ(timeFraction: number) {
        return 1 - Math.sin(Math.acos(timeFraction));
    },
    back: function back(x: number, timeFraction: number) {
        return Math.pow(timeFraction, 2) * ((x + 1) * timeFraction - x);
    }.bind(null, 1.5),
    bounce: function bounce(timeFraction: number) {
        for (let a = 0, b = 1; 1; a += b, b /= 2) {
            if (timeFraction >= (7 - 4 * a) / 11) {
                return -Math.pow((11 - 6 * a - 11 * timeFraction) / 4, 2) + Math.pow(b, 2);
            }
        }
        return 0;
    }
};

/** Appliquer l'effet en début et en fin */
export function makeEaseInOut(timing: Function) {
    return function (timeFraction: number) {
        if (timeFraction < .5)
            return timing(2 * timeFraction) / 2;
        else
            return (2 - timing(2 * (1 - timeFraction))) / 2;
    };
}

/** Appliquer l'effet en fin d'animation */
export function makeEaseOut(timing: Function) {
    return function (timeFraction: number) {
        return 1 - timing(1 - timeFraction);
    };
}

/**
 * Fonction globale d'animation JS
 * Ressources : https://javascript.info/js-animation
 */
export const Animate = ({ animation = "linear", mode = "easeIn", draw, duration = 1000, callback }: AnimateConfig) => {

    let start = performance.now();

    let timing = timingFn[animation];

    if (mode === "easeInOut") {
        timing = makeEaseInOut(timing);
    } else if (mode === "easeOut") {
        timing = makeEaseOut(timing);
    }

    requestAnimationFrame(function animate(time) {
        // timeFraction goes from 0 to 1
        let timeFraction = (time - start) / duration;
        if (timeFraction > 1) timeFraction = 1;

        // calculate the current animation state
        let progress = timing(timeFraction);

        draw(progress); // draw it

        if (timeFraction < 1) {
            requestAnimationFrame(animate);
        }

        if (timeFraction === 1 && callback) {
            callback();
        }

    });
};