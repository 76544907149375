

import { EsAutocompleteInputElement } from './components/es_autocomplete/es_autocomplete';
import { EsCollapseToggle } from './components/es_collapse';
import { EsFormStepsElement } from './components/es_form-steps';
import { ConfigMap } from "./components/es_search-form/ConfigMap.models";
import { Ajax } from "./components/es_tools/Ajax";
import { stringToElement } from "./components/es_tools/StringToElement";
import { EsLoad } from "./load";
import { evostrap } from "./main";

/**
 * Classe utilitaire qui regroupe des méthodes statiques pour initialiser les composants de l'interface utilisateur sur la page courante.
 * 
 * Chaque méthode est responsable de la configuration et de l'initialisation d'un type spécifique de composant,
 * en utilisant des éléments du DOM sélectionnés par des sélecteurs CSS.
 * 
 * Les méthodes chargent dynamiquement les modules nécessaires via des appels asynchrones à `EsLoad`, puis initialisent les composants correspondants.
 */
export class EsInit {
    public static readonly esHeader = () => {
        const elements = document.querySelectorAll<HTMLElement>('.es_header');
        if (elements.length) {
            EsLoad.esHeader().then(EsHeader => {
                elements.forEach(el => {
                    EsHeader.getOrCreateInstance(el);
                });
            });
        }
    };
    public static readonly esSearchFormLight = () => {
        const sidebarElement = document.querySelector<HTMLElement>('.es_search-form-sidebar-light');

        if (sidebarElement) {
            EsLoad.esSearchFormLight().then(EsSearchFormLight => {

                EsSearchFormLight.getOrCreateInstance(sidebarElement, {
                    responsive: {
                        mobileViewBreakpoints: ["xs", "sm", "md", "lg"],
                        touch: true,
                        touchTransitionDuration: 500,
                        submitOnClose: false,
                        selectedValuesSeparator: " <span class=\"text-muted\"> | </span> "
                    }
                });

            });
        }


    };
    public static readonly esVideo = () => {
        const elements = document.querySelectorAll<HTMLElement>('.es_video');
        if (elements.length) {
            EsLoad.esVideo().then(EsVideo => {
                elements.forEach(esVideoElement => {
                    if (esVideoElement.querySelector('video')) {
                        EsVideo.getOrCreateInstance(esVideoElement);
                    }
                });
            });
        }



    };
    public static readonly esAlert = () => {
        const alertElements = document.querySelectorAll<HTMLElement>('.alert');

        if (alertElements.length) {
            EsLoad.esAlert().then(EsAlert => {
                if (alertElements.length) {
                    alertElements.forEach(el => {
                        EsAlert.getOrCreateInstance(el);
                    });
                }
            });
        }
    };
    public static readonly esAlertDismiss = () => {
        EsLoad.esAlertDismiss().then(EsAlertDismiss => {
            EsAlertDismiss.init();
        });
    };
    public static readonly esAnimate = () => {
        EsLoad.esAnimate().then(EsAnimate => {
            EsAnimate.init();
        });
    };
    public static readonly esAsyncContent = () => {
        const elements = document.querySelectorAll<HTMLElement>('[data-async-src]');
        if (elements.length) {
            EsLoad.esAsyncContent().then(EsAsyncContent => {
                elements.forEach(el => EsAsyncContent.getOrCreateInstance(el));
            });
        }

    };
    public static readonly esAutoSubmit = () => {
        EsLoad.esAutoSubmit().then(EsAutoSubmit => {
            EsAutoSubmit.init();
        });
    };
    public static readonly esAutocomplete = () => {
        const elements = document.querySelectorAll<EsAutocompleteInputElement>('.es_autocomplete');

        if (elements.length) {
            EsLoad.esAutocomplete().then(EsAutocomplete => {
                elements.forEach(elInput => {
                    EsAutocomplete.getOrCreateInstance(elInput);
                });
            });
        }

    };
    public static readonly esBanner = () => {
        const elements = document.querySelectorAll('es-banner');
        if (elements.length) {
            EsLoad.esBanner();
        }
    };
    public static readonly esButtonToggle = () => {
        EsLoad.esButtonToggle().then(EsButtonToggle => {
            EsButtonToggle.init();
        });
    };
    public static readonly esCheckboxList = () => {
        const elements = document.querySelectorAll('es-checkbox-list');
        if (elements.length) {
            EsLoad.esCheckboxList();
        }
    };

    public static readonly esClearableInput = () => {
        const clearableElements = document.querySelectorAll<HTMLInputElement>('[data-clearable="true"]');
        if (clearableElements.length) {
            EsLoad.esClearableInput().then(EsClearableInput => {
                clearableElements.forEach(element => {
                    EsClearableInput.getOrCreateInstance(element);
                });
            });
        }
    };
    public static readonly esCollapse = () => {
        const collapseElements = document.querySelectorAll<HTMLElement>('.collapse');
        if (collapseElements.length) {
            EsLoad.esCollapse().then(EsCollapse => {

                collapseElements.forEach(collapseElement => {
                    EsCollapse.getOrCreateInstance(collapseElement);
                });

            });
        }
    };
    public static readonly esCollapseToggle = () => {
        return new Promise((resolve) => {
            resolve(EsCollapseToggle.init());
        });
    };

    public static readonly esCustomCheckbox = () => {
        const elements = document.querySelectorAll('es-custom-checkbox');

        if (elements.length) {
            EsLoad.esCustomCheckbox();
        }

    };
    public static readonly esDatepicker = () => {

        const elements = document.querySelectorAll<HTMLInputElement>(".es_datepicker");

        if (elements.length) {
            EsLoad.esDatepicker().then(EsDatepicker => {

                elements.forEach(datepicker => {
                    // Préchargement des périodes
                    if (datepicker.dataset.periodsSrc) {
                        let dataUrl = datepicker.dataset.periodsSrc;
                        Ajax.getJSON(dataUrl).then((data: any) => {
                            EsDatepicker.getOrCreateInstance(datepicker, {
                                periods: data.map((period: any) => {
                                    return {
                                        startDate: new Date(period.startDate),
                                        endDate: new Date(period.endDate)
                                    };
                                })
                            });

                        });

                    } else {
                        EsDatepicker.getOrCreateInstance(datepicker);
                    }
                });

            });
        }

    };
    public static readonly esDragAndDrop = () => {
        const elements = document.querySelectorAll<HTMLElement>('[data-toggle="draggable"]');

        if (elements.length) {
            EsLoad.esDragAndDrop().then(EsDragAndDrop => {
                elements.forEach(el => EsDragAndDrop.getOrCreateInstance(el));

            });
        }
    };
    public static readonly esDropdown = () => {
        const elements = document.querySelectorAll<HTMLButtonElement>('.dropdown-menu');

        if (elements.length) {
            EsLoad.esDropdown().then(EsDropdown => {

                elements.forEach(element => {
                    EsDropdown.getOrCreateInstance(element);
                });


            });
        }
    };
    public static readonly esDropdownToggle = () => {
        EsLoad.esDropdownToggle().then(EsDropdownToggle => {
            EsDropdownToggle.init();
        });
    };
    public static readonly esEuroMap = () => {
        const elements = document.querySelectorAll('es-euro-map');
        if (elements.length) {
            EsLoad.esEuroMap();
        }
    };
    public static readonly esFooter = () => {
        const elements = document.querySelectorAll<HTMLElement>('.es_footer');
        if (elements.length) {
            EsLoad.esFooter().then(EsFooter => {
                elements.forEach(element => EsFooter.getOrCreateInstance(element));
            });
        }
    };
    public static readonly esFormStatus = () => {
        const formsStatusElements = document.querySelectorAll<HTMLElement>('.es_form-status');

        EsLoad.esFormStatus().then(EsFormStatus => {
            formsStatusElements.forEach(element => {
                EsFormStatus.getOrCreateInstance(element);
            });
        });
    };
    public static readonly esFormSteps = () => {
        const elements = document.querySelectorAll<EsFormStepsElement>('.es_form-steps');
        if (elements.length) {
            EsLoad.esFormSteps().then(EsFormSteps => {
                elements.forEach(element => EsFormSteps.getOrCreateInstance(element));
            });
        }
    };
    public static readonly esFormValidation = () => {
        const elements = document.querySelectorAll<HTMLFormElement>('.es_form-validation');
        if (elements.length) {
            EsLoad.esFormValidation().then(EsFormValidation => {
                elements.forEach(element => {
                    EsFormValidation.getOrCreateInstance(element);
                });
            });
        }


    };
    public static readonly esFormValidationGroup = () => {
        const elements = document.querySelectorAll<HTMLElement>('[data-rule-group]');

        if (elements.length) {
            EsLoad.esFormValidationGroup().then(EsFormValidationGroup => {
                elements.forEach(element => {
                    EsFormValidationGroup.getOrCreateInstance(element);
                });
            });
        }


    };
    public static readonly esGlossary = () => {
        const elements = document.querySelectorAll<HTMLElement>('.es_glossary');
        const hashedLinks = document.querySelectorAll('.es_glossary a[href^="#"]');
        if (hashedLinks.length) {
            EsLoad.esGlossary().then(EsGlossary => {
                elements.forEach(glossaryElement => {
                    EsGlossary.getOrCreateInstance(glossaryElement);
                });
            });
        }

    };
    public static readonly esHemicycle = () => {
        const elements = document.querySelectorAll('es-hemicycle');
        if (elements.length) {
            EsLoad.esHemicycle();
        }
    };
    public static readonly esHemicycleDiscover = () => {
        const elements = document.querySelectorAll('es-hemicycle-discover');

        if (elements.length) {
            EsLoad.esHemicycleDiscover();
        }
    };
    public static readonly esIcons = () => {

        EsLoad.esIcons().then(spriteCSS => {
            let sprite = spriteCSS;
            document.body.insertAdjacentHTML("afterbegin", sprite);
        });

    };
    public static readonly esInputRange = () => {
        const elements = document.querySelectorAll('es-input-range');
        if (elements.length) {
            EsLoad.esInputRange();
        }
    };
    public static readonly esModal = () => {
        const modalElements = document.querySelectorAll<HTMLElement>('.modal');

        if (modalElements.length) {
            EsLoad.esModal().then(EsModal => {
                modalElements.forEach(modal => {
                    EsModal.getOrCreateInstance(modal);
                });
            });
        }
    };
    public static readonly esModalToggle = () => {
        EsLoad.esModalToggle().then(EsModalToggle => EsModalToggle.init());
    };
    public static readonly esMove = () => {
        EsLoad.esMove().then(EsMove => EsMove.init());
    };
    public static readonly esOverlay = () => {
        const elements = document.querySelectorAll('es-overlay');
        if (elements.length) {
            EsLoad.esOverlay();
        }
    };
    public static readonly esPopover = () => {
        const elements = document.querySelectorAll<HTMLElement>('[data-toggle="popover"]');
        if (elements.length) {
            EsLoad.esPopover().then(EsPopover => {
                elements.forEach(element => EsPopover.getOrCreateInstance(element));
            });
        }
    };
    public static readonly esRefiner = () => {
        const elements = document.querySelectorAll<HTMLInputElement>('.es_refiner');
        if (elements.length) {
            EsLoad.esRefiner().then(EsRefiner => {
                elements.forEach(elInput => {
                    EsRefiner.getOrCreateInstance(elInput);
                });
            });
        }
    };
    public static readonly esResize = () => {
        EsLoad.esResize().then(EsResize => {
            EsResize.listen();
        });
    };
    public static readonly esResponsive = () => {
        EsLoad.esResponsive().then(EsResponsive => {
            EsResponsive.init();
        });
    };
    public static readonly esScrollSpy = () => {

        // Gestion des scrollspy par attributs. 
        const scrollSpyElements = document.querySelectorAll<HTMLElement>('[data-scroll-spy]');

        if (scrollSpyElements.length) {
            EsLoad.esScrollSpy().then(EsScrollSpy => {

                scrollSpyElements.forEach(spyElement => {
                    const toggleAttribute = spyElement.dataset.scrollSpyToggleAttribute;
                    const toggleClass = spyElement.dataset.scrollSpyToggleClass;
                    const spyItemsSelector = spyElement.dataset.scrollSpy;
                    const reflectToElement = spyElement.dataset.reflectToElement ? document.querySelector<HTMLElement>(spyElement.dataset.reflectToElement) : null;
                    const container = spyElement.dataset.scrollSpyContainer ? document.querySelector<HTMLElement>(spyElement.dataset.scrollSpyContainer) : null;
                    const spyItems = spyItemsSelector ? Array.from(spyElement.querySelectorAll<HTMLElement>(spyItemsSelector)) : [];
                    let topOffset: string | null | number = null;
                    if (spyElement.dataset.scrollSpyTopOffset) {
                        if (spyElement.dataset.scrollSpyTopOffset.endsWith('%') || spyElement.dataset.scrollSpyTopOffset.endsWith('px')) {
                            topOffset = spyElement.dataset.scrollSpyTopOffset;
                        } else {
                            topOffset = parseFloat(spyElement.dataset.scrollSpyTopOffset);
                        }
                    }

                    new EsScrollSpy(spyItems, {
                        toggleAttribute: toggleAttribute,
                        toggleClass: toggleClass,
                        reflectToElement: reflectToElement,
                        container: container,
                        topOffset: topOffset

                    });
                });

            });
        }

    };
    public static readonly esScrollToTop = () => {

        const triggerButton = document.getElementById('scrollToTopButton') as HTMLButtonElement | null;
        if (triggerButton) {
            EsLoad.esScrollToTop().then(EsScrollToTop => {
                EsScrollToTop.getOrCreateInstance(triggerButton);

            });
        }

    };
    public static readonly esScrollbar = () => {

        const elements = document.querySelectorAll<HTMLElement>('.es_scrollbar');

        if (elements.length) {
            EsLoad.esScrollbar().then(EsScrollbar => {
                elements.forEach(element => {
                    EsScrollbar.getOrCreateInstance(element);
                });
            });
        }



    };
    public static readonly esSearchForm = () => {

        let sidebarElement = document.querySelector<HTMLElement>('.es_search-form-sidebar');

        if (sidebarElement) {
            EsLoad.esSearchForm().then(EsSearchForm => {
                // INIT
                if (sidebarElement) {

                    let initialFacetsUrl = sidebarElement.dataset.initialFacetsUrl;
                    let loadingDelay = sidebarElement.dataset.initialShowLoadingDelay;

                    let loadingTimeout = setTimeout(() => {
                        evostrap.loading(true);
                    }, loadingDelay ? parseInt(loadingDelay) : 250);

                    if (!initialFacetsUrl) {
                        return;
                    }

                    Ajax.httpRequest<ConfigMap>({
                        url: initialFacetsUrl,
                        method: "GET",
                        withCredentials: true,
                        contentType: "application/json"
                    }).then(res => {
                        clearTimeout(loadingTimeout);

                        // Affichage spinner
                        evostrap.loading(false);

                        if (sidebarElement) {
                            EsSearchForm.getOrCreateInstance(sidebarElement, res);
                        }

                    }).catch(err => {
                        if (loadingTimeout) {
                            clearTimeout(loadingTimeout);
                        }

                        // Affichage spinner
                        evostrap.loading(false);

                        console.error(err);
                        if (sidebarElement)
                            sidebarElement.innerHTML = `<div class="alert alert-danger">${sidebarElement.dataset.errorMessage || err.statusText}</div>`;

                    });
                }

            });
        }

    };
    public static readonly esSelect = () => {

        const elements = document.querySelectorAll<HTMLSelectElement>('.es_select');

        elements.forEach(elSelect => {
            EsLoad.esSelect().then(EsSelect => {
                EsSelect.getOrCreateInstance(elSelect, {
                    minLengthForSearch: 20
                });
            });
        });
    };
    public static readonly esSelectMeps = () => {
        const elements = document.querySelectorAll<HTMLSelectElement>('select.es_select-meps');

        if (elements.length) {
            EsLoad.esSelect().then(EsSelect => {
                elements.forEach(mepsSelectElement => {
                    let source = mepsSelectElement.dataset.source;

                    if (!source) {
                        console.warn("Please provide a source for ", mepsSelectElement);
                        return;
                    }

                    Ajax.getJSON(source).then(mepsJSON => {
                        mepsJSON.forEach((mep: any) => {
                            mepsSelectElement.appendChild(stringToElement(`<option value="${mep.id}" data-id="${mep.id}">${mep.firstName} ${mep.upperLastName}</option>`));
                        });

                        EsSelect.getOrCreateInstance(mepsSelectElement, {
                            optionTemplate: (optionElement) => {
                                if (optionElement.dataset.id) {
                                    return `<li role="option" class="es_select-options-item" tabindex="-1"> <span class="es_select-mep-picture"><img loading="lazy" src="https://www.europarl.europa.eu/mepphoto/{{id}}.jpg" alt=""/></span> <span>{{label}}</span></li>`;
                                } else {
                                    return `<li role="option" class="es_select-options-item" tabindex="-1"><span>{{label}}</span></li>`;
                                }
                            }
                        });

                    });
                });
            });
        }

    };
    public static readonly esSelectOrgan = () => {
        const elements = document.querySelectorAll<HTMLSelectElement>('.es_select-organ');

        if (elements.length) {
            EsLoad.esSelect().then(EsSelect => {
                elements.forEach((selectEl) => {
                    EsSelect.getOrCreateInstance(selectEl, {
                        minLengthForSearch: 20,
                        optionTemplate: (optionElement) => {
                            let organText = optionElement.dataset.organText || optionElement.dataset.additionaltext || "";
                            let organClass = optionElement.dataset.organClass || optionElement.dataset.additionaltextClass || "es_badge-outline-red";
                            return `<li role="option" aria-selected="false" class="es_select-options-item" tabindex="-1"> <span class="es_select-options-organ es_badge ${organClass} ml-{{organLevel}} mr-25">${organText}</span> <span>{{label}}</span></li>`;
                        },
                        optionSearchTerm: (optionElement) => {
                            return [optionElement.dataset.organText || optionElement.dataset.additionaltext || "", optionElement.value, optionElement.label].join(' ');
                        }
                    });

                });
            });
        }

    };
    public static readonly esSlideshow = () => {

        // Module.EsSlideshow.init();
        const slideshows = document.querySelectorAll<HTMLElement>('.es_slideshow');
        if (slideshows.length) {
            EsLoad.esSlideshow().then(EsSlideshow => {
                slideshows.forEach(slideshow => {
                    EsSlideshow.getOrCreateInstance(slideshow);
                });
            });
        }


    };
    public static readonly esSmoothScroll = () => {
        EsLoad.esSmoothScroll().then(EsSmoothScroll => EsSmoothScroll.init());
    };
    public static readonly esSplitScreen = () => {
        const elements = document.querySelectorAll('es-split-screen');
        if (elements.length) {
            EsLoad.esSplitScreen();
        }

    };
    public static readonly esStatsCircle = () => {
        const elements = document.querySelectorAll('es-stats-circle');
        if (elements.length) {
            EsLoad.esStatsCircle();
        }

    };
    public static readonly esSticky = () => {
        const stickyElements = document.querySelectorAll<HTMLElement>('[data-sticky]');

        if (stickyElements.length) {

            EsLoad.esSticky().then(EsSticky => {
                stickyElements.forEach(element => {
                    EsSticky.getOrCreateInstance(element);
                });
            });
        }



    };
    public static readonly esTab = () => {

        const tabElements = document.querySelectorAll<HTMLElement>('.tab-pane');
        if (tabElements.length) {
            EsLoad.esTab().then(EsTab => {

                tabElements.forEach(modal => {
                    EsTab.getOrCreateInstance(modal);
                });

            });
        }



    };
    public static readonly esTabToggle = () => {
        EsLoad.esTabToggle().then(EsTabToggle => EsTabToggle.init());
    };
    public static readonly esTagsInput = () => {
        const elements = document.querySelectorAll('es-tags-input');
        if (elements.length) {
            EsLoad.esTagsInput();
        }

    };
    public static readonly esTooltip = () => {
        const elements = document.querySelectorAll<HTMLElement>('[data-toggle="tooltip"]');

        if (elements.length) {
            EsLoad.esTooltip().then(EsTooltip => {
                elements.forEach(element => EsTooltip.getOrCreateInstance(element));

            });
        }

    };
    public static readonly esTree = () => {
        const elements = document.querySelectorAll('es-tree');

        if (elements.length) {
            EsLoad.esTree();
        }
    };

    private constructor() { }
}
