

/** EVOSTRAP */
export let _public_path__: any = document.getElementById('evostrap') ? document.getElementById('evostrap')?.getAttribute('src')?.split('js/evostrap.js')[0] : '/evostrap/dist/';



import iconList from 'evostrap-icons/dist/es_icon/es_icon.json';

import { EsIcon } from './components/es_icon/es_icon';
import { EsToast } from './components/es_toast';
import { debounce } from './components/es_tools/Debounce';
import { Ajax, Breakpoints, Cookie, Sanitizer, focusable, loading, touch } from './components/es_tools/index';
import { EsInit } from './init';
import { EsLoad } from './load';


export class Evostrap {
    private constructor() { }
    static sanitizer = Sanitizer;
    static ajax = Ajax;
    static breakpoints = Breakpoints;
    static debounce = debounce;
    static focusable = focusable;
    static icon = new EsIcon(Array.from(iconList));
    static load = EsLoad;
    static init = EsInit;
    static cookie = Cookie;
    static loading = loading;
    static toast = EsToast.show;
    static touch = touch;
}

export const evostrap = Evostrap;

// Exposition global
(<any>window).evostrap = evostrap;

// Chargement de l'ensemble des scripts
for (const key in evostrap.init) {
    if (evostrap.init.hasOwnProperty(key)) {
        (evostrap.init[key as keyof typeof evostrap.init] as Function)()?.catch((err: any) => {
            console.log(key, err);
        });
    }
}