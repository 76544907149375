/** Définition du type d'événement. */
export interface StoreEventListenerItem<K extends keyof HTMLElementEventMap> {
  element: HTMLElement | Document | Window;
  type: K | string;
  listener: EventListenerOrEventListenerObject;
}

/** C'est une classe qui stocke les écouteurs d'événements et vous permet de les supprimer tous en même temps */
export class StoreEventListener {
  /** Liste des événements courants. */
  listeners: StoreEventListenerItem<any>[] = [];

  /**
   * Ajoute un écouteur d'événement à un élément.
   * @param {HTMLElement | Window | Document} element - L'élément auquel ajouter l'écouteur d'événement.
   * @param {keyof HTMLElementEventMap} type - Le type d'événement à écouter.
   * @param {EventListenerOrEventListenerObject} listener - La fonction à appeler lorsque l'événement se produit.
   * @returns La valeur de retour de la fonction addEventListener.
   */
  addEventListener<T extends HTMLElement | Window | Document, K extends keyof HTMLElementEventMap>(
    element: T,
    type: K,
    listener: (this: T, ev: HTMLElementEventMap[K]) => any,
    options?: AddEventListenerOptions
  ): void {
    this.listeners.push({
      element: element,
      type: type,
      listener: listener as EventListenerOrEventListenerObject
    });

    element.addEventListener(type, listener as EventListener, options);
  }

  /**
  * Prend un élément, un type d'événement et une fonction d'écoute, puis ajoute l'écouteur d'événement à l'élément
  * @param {HTMLElement | Window | Document} element - L'élément auquel ajouter l'écouteur d'événement.
  * @param {string} type - Le type d'événement à écouter.
  * @param {EventListenerOrEventListenerObject} listener - La fonction qui sera appelée lorsque l'événement est déclenché.
  * @param {AddEventListenerOptions} [options] - Les options à utiliser lors de l'ajout de l'écouteur d'événement.     * @returns La valeur de retour de la fonction addEventListener.
  */
  addCustomEventListener<T extends HTMLElement | Window | Document>(element: T, type: string, listener: EventListenerOrEventListenerObject, options?: AddEventListenerOptions): void {
    this.listeners.push({
      element: element,
      type: type,
      listener: listener,
    });

    return element.addEventListener(type, listener, options);
  }


  /**
   * Supprime un écouteur d'événement d'un élément.
   * @param element - L'élément à partir duquel supprimer l'écouteur d'événement.
   * @param event - Type d'événement à écouter.
   * @param {EventListenerOrEventListenerObject} listener - EventListenerOrEventListenerObject
   */
  removeEventListener<K extends keyof HTMLElementEventMap>(
    element: StoreEventListenerItem<K>['element'],
    event: StoreEventListenerItem<K>['type'],
    listener: EventListenerOrEventListenerObject
  ) {
    this.listeners = this.listeners.filter(
      currentEvent => currentEvent.element !== element && currentEvent.type !== event && currentEvent.listener !== listener
    );
    element.removeEventListener(event, listener as EventListener);
  }

  /**
   * Supprime tous les écouteurs d'événements du tableau des événements
   */
  removeAllEventListeners() {
    this.listeners.forEach(event => {
      event.element.removeEventListener(event.type, event.listener);
    });
  }
}
