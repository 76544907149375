/** 
 * Outils Ajax 
 * Ressources: 
 * https://developer.mozilla.org/fr/docs/Web/API/XMLHttpRequest
 * https://blog.garstasio.com/you-dont-need-jquery/ajax/
 */

/**
 * Interface de configuration pour les requêtes HTTP.
 */
export interface HttpRequestConfig {
  /** Données transmises avec la requête. */
  body?: string | FormData | Document | Blob | ArrayBufferView | ArrayBuffer | URLSearchParams | ReadableStream<Uint8Array> | null | undefined;
  /** Type MIME du contenu. */
  contentType?: string;
  /** Méthode HTTP autorisée pour la requête. */
  method: 'GET' | 'POST' | 'PUT' | "DELETE" | "PATCH" | "OPTIONS" | "HEAD";
  /** Indique si le corps doit être traité comme une URLParams; valeur par défaut: `true`. */
  processData?: boolean;
  /** URL de la requête. */
  url: string;
  /** Indique si les informations d'identification doivent être incluses. */
  withCredentials?: boolean;
}

/** 
 * Classe qui permet de faire des requêtes HTTP via XMLHttpRequest.
 */
export class Ajax {
  private constructor() { }

  /**
   * Effectue une requête HTTP GET.
   * @param url - L'URL à laquelle faire la demande.
   * @param data - Les données à envoyer au serveur.
   * @returns Une promesse résolue avec la réponse de la requête.
   */
  static get<T = any>(url: string, data?: any) {
    return this.httpRequest<T>({
      method: "GET",
      url: url,
      body: data
    });
  }

  /**
   * Effectue une requête HTTP GET et parse la réponse en JSON.
   * @param url - L'URL à laquelle faire la demande.
   * @param data - Les données à envoyer au serveur.
   * @returns Une promesse résolue avec un objet JSON représentant la réponse.
   */
  static getJSON<T = any>(url: string, data?: any) {
    return this.httpRequest<T>({
      method: "GET",
      url: url,
      body: data,
      contentType: "application/json"
    });
  }

  /**
   * Charge un script dynamiquement via une balise `<script>`.
   * @param url - L'URL du script à charger.
   * @returns Une promesse résolue avec un booléen indiquant le succès du chargement.
   */
  static getScript(url: string) {
    return new Promise<boolean>((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.type = "text/javascript";
      scriptElement.src = url;

      // Ajoute un gestionnaire d'événement pour appeler le callback une fois le script chargé
      scriptElement.onload = function () {
        resolve(true);
      };

      // Gérer les erreurs de chargement du script
      scriptElement.onerror = function (ev) {
        console.error('Error when loading the script:', url);
        reject(ev);
      };

      // Ajouter le script à la balise <head> ou <body>
      document.head.appendChild(scriptElement);
    });
  }

  /**
   * Effectue une requête HTTP avec la configuration fournie.
   * @param config - Configuration de la requête HTTP.
   * @returns Une promesse résolue avec la réponse de la requête.
   */
  static httpRequest<T = any>({ method, url, body, contentType, processData = true, withCredentials }: HttpRequestConfig) {
    return new Promise((resolve: (value: T) => void, reject: (reason?: any) => void) => {
      let httpRequest = new XMLHttpRequest();

      if (!httpRequest) {
        reject('Impossible de créer une instance de XMLHttpRequest');
      }

      if (!navigator.onLine) {
        reject({ responseText: 'Navigator is offline' });
      }

      httpRequest.onreadystatechange = function () {
        if (httpRequest.readyState === XMLHttpRequest.DONE) {
          if (httpRequest.status === 200) {
            if (contentType?.includes('json')) {
              resolve(JSON.parse(httpRequest.responseText));
            } else {
              resolve(<any>httpRequest.responseText);
            }
          } else {
            reject(httpRequest);
          }
        }
      };

      httpRequest.open(method, this.populateRequestURL({ url, method, body, processData }));

      if (contentType) {
        httpRequest.setRequestHeader('Content-Type', contentType);
      }

      if (withCredentials) {
        httpRequest.withCredentials = true;
      }

      // Gestion du corps de la requête
      if (body && processData) {
        if (body instanceof Object) {
          body = this.dataToSearchParams(body).toString();
        }
      }

      httpRequest.send(<any>body);
    });
  }

  /**
   * Effectue une requête HTTP POST.
   * @param url - L'URL à laquelle faire la demande.
   * @param data - Les données à envoyer au serveur.
   * @param contentType - Le type de contenu de la demande.
   * @returns Une promesse résolue avec la réponse de la requête.
   */
  static post<T = any>(url: string, data?: any, contentType?: string) {
    return this.httpRequest<T>({
      method: "POST",
      url: url,
      body: data,
      contentType: contentType
    });
  }

  /**
   * Ajoute les données à l'URL de la requête.
   * @param url - L'URL d'origine.
   * @param data - Les données à ajouter à l'URL.
   * @returns L'URL modifiée avec les données ajoutées.
   */
  private static appendDataToURL(url: string, data: string | Record<string, any>): string {
    const parsedURL = new URL(url, document.URL);
    const searchParams = parsedURL.searchParams;

    const dataParams = this.dataToSearchParams(data);
    for (const [key, value] of dataParams.entries()) {
      searchParams.append(key, value);
    }

    return parsedURL.toString();
  }

  /**
   * Convertit les données en URLSearchParams.
   * @param data - Les données à convertir.
   * @returns Les données converties en URLSearchParams.
   */
  private static dataToSearchParams(data: any) {
    const searchParams = new URLSearchParams();
    if (typeof data === 'string') {
      const queryParams = new URLSearchParams(data);
      for (const [key, value] of queryParams.entries()) {
        searchParams.append(key, value);
      }
    } else {
      const flattenObject = (obj: Record<string, any>, prefix = ''): void => {
        for (const [key, value] of Object.entries(obj)) {
          const prefixedKey = prefix ? `${prefix}[${key}]` : key;
          if (typeof value === 'object' && !Array.isArray(value)) {
            flattenObject(value, prefixedKey);
          } else {
            searchParams.append(prefixedKey, value);
          }
        }
      };
      flattenObject(data);
    }

    return searchParams;
  }

  /**
   * Nettoie l'URL de la requête en ajoutant les données si nécessaire.
   * @param config - Configuration de la requête HTTP.
   * @returns L'URL de la requête, éventuellement modifiée.
   */
  private static populateRequestURL({ method, url, body, processData }: HttpRequestConfig) {
    if (processData && body) {
      if ((method === "GET" || method === "HEAD" || method === "OPTIONS") && processData) {
        return this.appendDataToURL(url, body);
      } else {
        return url;
      }
    }
    return url;
  }
}
