import DOMPurify, { Config } from 'dompurify';

/** 
 * Permettre l'utilisation de `<use>` avec les éléments SVG.
 * https://github.com/cure53/DOMPurify/issues/574
 */
DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    const href = node.getAttribute('xlink:href') || node.getAttribute('href');
    if (href && node instanceof SVGUseElement && !href.startsWith('#')) {
        node.removeAttribute('xlink:href');
        node.removeAttribute('href');
    }

    if (href && node instanceof HTMLAnchorElement && node.target === "_blank") {
        node.rel = "noopener noreferrer";
    }
});

/** 
 * Type de configuration pour la fonction `sanitize`, étendant le type `Config` de DOMPurify.
 */
export type SanitizeConfig = Config & {
    RETURN_DOM_FRAGMENT?: false | undefined;
    RETURN_DOM?: false | undefined;
};

/** 
 * Classe `Sanitizer` fournissant des méthodes pour nettoyer des chaînes de caractères potentiellement dangereuses 
 * en utilisant DOMPurify.
 */
export class Sanitizer {
    /** 
     * Configuration par défaut pour la méthode `sanitize`, incluant des profils d'utilisation pour SVG, 
     * les filtres SVG, HTML et MathML, et ajoutant la balise `use`.
     */
    public static configuration: SanitizeConfig = {
        USE_PROFILES: { svg: true, svgFilters: true, html: true, mathMl: true },
        ADD_TAGS: ['use'],
        ADD_ATTR: ['target'],
        CUSTOM_ELEMENT_HANDLING: {
            tagNameCheck: /^es-/, // Permet toutes les balises commençant par "es-"
            attributeNameCheck: (attr) => {
                return !attr.startsWith('on');
            },
            allowCustomizedBuiltInElements: false, // N'autorise pas les éléments intégrés personnalisés
        },
    };

    private constructor() { }

    /** 
     * Récupère l'instance DOMPurify.
     * @returns L'instance DOMPurify.
     */
    public static get DOMPurifyI(): DOMPurify.DOMPurifyI {
        return DOMPurify;
    }

    /**
     * La méthode `sanitize` utilise DOMPurify pour nettoyer une chaîne de caractères potentiellement dangereuse,
     * assurant ainsi la sécurité lors de l'insertion de contenu dans le DOM.
     * @param dirtyString - La chaîne de caractères à nettoyer.
     * @param config - Configuration optionnelle pour la personnalisation du nettoyage.
     * @returns La chaîne de caractères nettoyée et sécurisée.
     */
    public static sanitize(dirtyString: string, config: SanitizeConfig = this.configuration) {
        return DOMPurify.sanitize(dirtyString, config);
    }
}
