

export class EsLoad {
    public static async esHeader() {
        return (await import('./components/es_header')).EsHeader;
    };

    public static async esSearchFormLight() {
        return (await import('./components/es_search-form-light')).EsSearchFormLight;
    };

    public static async esVideo() {
        return (await import('./components/es_video')).EsVideo;
    };

    public static async esAlert() {
        return (await import('./components/es_alert')).EsAlert;
    };

    public static async esAlertDismiss() {
        return (await import('./components/es_alert')).EsAlertDismiss;
    };

    public static async esAnimate() {
        return (await import('./components/es_animate')).EsAnimate;
    };

    public static async esAsyncContent() {
        return (await import('./components/es_async-content')).EsAsyncContent;
    };

    public static async esAutoSubmit() {
        return (await import('./components/es_auto-submit')).EsAutoSubmit;
    };

    public static async esAutocomplete() {
        return (await import('./components/es_autocomplete')).EsAutocomplete;
    };

    public static async esBanner() {
        return (await import('./lit/es_banner')).EsBannerElement;
    };

    public static async esButtonToggle() {
        return (await import('./components/es_button')).EsButtonToggle;
    };

    public static async esCheckboxList() {
        return (await import('./lit/es_checkbox-list')).EsCheckboxListElement;
    };

    public static async esClearableInput() {
        return (await import('./components/es_clearable-input')).EsClearableInput;
    };

    public static async esCollapse() {
        return (await import('./components/es_collapse')).EsCollapse;
    };

    public static async esCollapseToggle() {
        return (await import('./components/es_collapse')).EsCollapseToggle;
    };

    public static async esContextMenu() {
        return (await import('./components/es_context-menu')).EsContextMenu;
    };

    public static async esCustomCheckbox() {
        return (await import('./lit/es_custom-checkbox')).EsCustomCheckboxElement;
    };

    public static async esDatepicker() {
        return (await import('./components/es_datepicker')).EsDatepicker;
    };

    public static async esDragAndDrop() {
        return (await import('./components/es_drag-and-drop')).EsDragAndDrop;
    };

    public static async esDropdown() {
        return (await import('./components/es_dropdown')).EsDropdown;
    };

    public static async esDropdownToggle() {
        return (await import('./components/es_dropdown')).EsDropdownToggle;
    };

    public static async esEuroMap() {
        return (await import('./lit/es_euro-map')).EsEuroMapElement;
    };

    public static async esFooter() {
        return (await import('./components/es_footer')).EsFooter;
    };

    public static async esFormStatus() {
        return (await import('./components/es_form-status')).EsFormStatus;
    };

    public static async esFormSteps() {
        return (await import('./components/es_form-steps')).EsFormSteps;
    };

    public static async esFormValidation() {
        return (await import('./components/es_form-validation')).EsFormValidation;
    };

    public static async esFormValidationGroup() {
        return (await import('./components/es_form-validation')).EsFormValidationGroup;
    };

    public static async esGlossary() {
        return (await import('./components/es_glossary')).EsGlossary;
    };

    public static async esHemicycle() {
        return (await import('./lit/es_hemicycle')).EsHemicycleElement;
    };

    public static async esHemicycleDiscover() {
        return (await import('./lit/es_hemicycle-discover')).EsHemicycleDiscoverElement;
    };

    public static async esIcons() {
        return (await import('evostrap-icons/dist/es_icon/sprites.svg?raw')).default;
    };

    public static async esInputRange() {
        return (await import('./lit/es_input-range')).EsInputRange;
    };

    public static async esModal() {
        return (await import('./components/es_modal')).EsModal;
    };

    public static async esModalToggle() {
        return (await import('./components/es_modal')).EsModalToggle;
    };

    public static async esMove() {
        return (await import('./components/es_move')).EsMove;
    };

    public static async esOverlay() {
        return (await import('./lit/es_overlay')).EsOverlayElement;
    };

    public static async esPopover() {
        return (await import('./components/es_tooltip')).EsPopover;
    };

    public static async esRefiner() {
        return (await import('./components/es_refiner')).EsRefiner;
    };

    public static async esResize() {
        return (await import('./components/es_tools/EsWindowResize')).EsWindowResize;
    };

    public static async esResponsive() {
        return (await (import('./components/es_responsive'))).EsResponsive;
    };

    public static async esScrollSpy() {
        return (await import('./components/es_scroll-spy')).EsScrollSpy;
    };

    public static async esScrollToTop() {
        return (await import('./components/es_scroll-to-top')).EsScrollToTop;
    };

    public static async esScrollbar() {
        return (await import('./components/es_scrollbar')).EsScrollbar;
    };

    public static async esSearchForm() {
        return (await import('./components/es_search-form')).EsSearchForm;
    };

    public static async esSelect() {
        return (await import('./components/es_select')).EsSelect;
    };

    public static async esSelectMeps() {

        return (await import('./components/es_select')).EsSelect;
    };

    public static async esSelectOrgan() {
        return (await import('./components/es_select')).EsSelect;
    };

    public static async esSlideshow() {
        return (await import('./components/es_slideshow')).EsSlideshow;
    };

    public static async esSmoothScroll() {
        return (await import('./components/es_smooth-scroll')).EsSmoothScroll;
    };

    public static async esSplitScreen() {
        return (await import('./lit/es_split-screen')).EsSplitScreenElement;
    };

    public static async esStatsCircle() {
        return (await import('./lit/es_stats-circle')).EsStatsCircleElement;
    };

    public static async esSticky() {
        return (await import('./components/es_sticky')).EsSticky;
    };

    public static async esTab() {
        return (await import('./components/es_tab')).EsTab;
    };

    public static async esTabToggle() {
        return (await import('./components/es_tab')).EsTabToggle;
    };

    public static async esTagsInput() {
        return (await import('./lit/es_tags-input')).EsTagsInputElement;
    };

    public static async esToast() {
        return (await import('./components/es_toast')).EsToast;
    };

    public static async esTooltip() {
        return (await import('./components/es_tooltip')).EsTooltip;
    };

    public static async esTree() {
        return (await import('./lit/es_tree')).EsTreeElement;
    };


    private constructor() { }
}