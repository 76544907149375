import { Sanitizer } from "../es_tools/Sanitizer";

/**
 * Types d'animations disponibles pour les toasts.
 */
export type EsToastAnimation =
    | "bounce"
    | "pulse"
    | "shake"
    | "bounceIn"
    | "bounceOut"
    | "fadeIn"
    | "fadeOut"
    | "flip"
    | "flipInX"
    | "flipInY"
    | "flipOutX"
    | "flipOutY"
    | "zoomIn"
    | "zoomOut"
    | "slideInDown"
    | "slideInLeft"
    | "slideInRight"
    | "slideInUp"
    | "slideOutDown"
    | "slideOutLeft"
    | "slideOutRight"
    | "slideOutUp";

/**
 * Configuration pour afficher un toast.
 */
export interface EsToastConfig {
    /** Animation d'entrée du toast. */
    animationIn: EsToastAnimation;
    /** Durée de l'animation d'entrée en millisecondes. */
    animationInDuration: number;
    /** Animation de sortie du toast. */
    animationOut: EsToastAnimation;
    /** Durée de l'animation de sortie en millisecondes. */
    animationOutDuration: number;
    /** Délai d'affichage du toast en millisecondes avant l'apparition. */
    delay: number;
    /** Durée totale d'affichage du toast en millisecondes. */
    duration: number;
    /**
     * Fonction de rappel appelée lorsque le toast est fermé.
     * @param toastElement - Élément HTML du toast fermé.
     */
    onCloseCallback?: (toastElement: HTMLElement) => void;
    /**
     * Fonction de rappel appelée lors de l'initialisation du toast.
     * @param toastElement - Élément HTML du toast initialisé.
     */
    onInitCallback?: (toastElement: HTMLElement) => void;
    /** Position du toast sur l'écran. */
    position: "top right" | "top left" | "bottom right" | "bottom left";
    /** Taille du toast ('auto' ou '100%'). */
    size: "auto" | "100%";
    /** Style visuel du toast. */
    style:
    | "primary"
    | "secondary"
    | "info"
    | "warning"
    | "success"
    | "danger"
    | "light";
    /** Texte affiché dans le toast. */
    text: string;
}

/**
 * Classe représentant un composant de toast.
 * @public
 */
export class EsToast {
    // Options par défaut pour les toasts
    private static defaultOptions: EsToastConfig = {
        text: "",
        style: "primary",
        duration: 2500,
        animationIn: "slideInRight",
        animationInDuration: 500,
        animationOut: "slideOutRight",
        animationOutDuration: 500,
        delay: 0,
        size: "auto",
        position: "top right",
        onCloseCallback: undefined,
        onInitCallback: undefined,
    };

    private constructor() { }

    /**
     * Ferme le toast spécifié avec l'animation de fermeture.
     * @param toastElement - L'élément HTML du toast à fermer.
     * @param config - Configuration du toast.
     */
    public static close(toastElement: HTMLElement, config: EsToastConfig) {
        // Fixer la durée de l'animation de fermeture
        toastElement.style.animationDuration = config.animationOutDuration + "ms";

        // Remplacer l'animation d'ouverture par celle de fermeture
        toastElement.classList.remove(config.animationIn);
        toastElement.classList.add(config.animationOut);

        // En fin d'animation réduire l'espace occupé
        setTimeout(function () {
            toastElement.classList.add("toastOut");

            // En fin de réduction de l'espace, supprimer le toast
            setTimeout(function () {
                toastElement.remove();

                const container = EsToast.getContainer(config.position);

                // Si il n'y a plus de Toast dans le container, le supprimer
                if (container && !container.children.length) {
                    container.remove();
                }

                // Si un callback de fermeture existe, l'executer
                if (config.onCloseCallback) {
                    config.onCloseCallback(toastElement);
                }
            }, 500);
        }, config.animationOutDuration);
    }

    /**
     * Affiche un nouveau toast avec la configuration spécifiée.
     * @param toastConfig - Configuration du toast à afficher.
     * @returns L'élément HTML du toast créé.
     */
    public static show(toastConfig: Partial<EsToastConfig> | string) {
        let config: EsToastConfig;

        if (typeof toastConfig === "string") {
            config = EsToast.defaultOptions;
            config.text = toastConfig;
        } else {
            config = { ...EsToast.defaultOptions, ...toastConfig };
        }

        // Si un container est présent, l'utiliser sinon le créer;
        let container = EsToast.getOrCreateContainer(config.position);

        // Ajouter la progressbar uniquement si la durée est > à 0
        let progressBarElement: HTMLElement | undefined;

        if (config.duration) {
            progressBarElement = document.createElement("span");
            progressBarElement.style.animationDuration = config.duration + "ms";
            progressBarElement.classList.add("es_toast-progressbar");
        }

        // Size
        let alignSelf;
        if (config.size === "auto" && config.position === "top right") {
            alignSelf = "flex-end";
        } else if (config.size === "auto" && config.position === "top left") {
            alignSelf = "flex-start";
        } else {
            alignSelf = "inherit";
        }

        // Création du Toast
        const toastElement = document.createElement("div");
        toastElement.setAttribute("role", "alert");
        toastElement.classList.add(
            "es_toast",
            "mx-1",
            "animated",
            config.animationIn,
            "alert",
            `alert-${config.style}`
        );
        toastElement.style.animationDuration = config.animationInDuration + "ms";
        toastElement.style.alignSelf = alignSelf;

        const textElement = document.createElement("span");
        textElement.classList.add("es_toast-text");
        textElement.innerHTML = Sanitizer.sanitize(config.text);

        toastElement.appendChild(textElement);

        if (progressBarElement) {
            toastElement.appendChild(progressBarElement);
        }

        // Insertion
        setTimeout(() => {
            // Si en top appendTo sinon PrependTo
            if (config.position === "top right" || config.position === "top left") {
                container.appendChild(toastElement);
            } else {
                container.prepend(toastElement);
            }

            // Si initCallback
            if (config.onInitCallback) {
                config.onInitCallback(toastElement);
            }

            // Fermeture au click ou durée.
            toastElement.addEventListener("click", () => {
                EsToast.close(toastElement, config);
            });

            if (config.duration) {
                setTimeout(() => {
                    EsToast.close(toastElement, config);
                }, config.duration);
            }
        }, config.delay);

        return toastElement;
    }

    /**
     * Récupère le container existant pour la position spécifiée, s'il existe.
     * @param position - Position du toast ('top right' | 'top left' | 'bottom right' | 'bottom left').
     * @returns Le container HTML du toast.
     */
    private static getContainer(position: EsToastConfig["position"]) {
        return document.querySelector<HTMLElement>(
            `.es_toast-container[data-position="${position}"]`
        );
    }

    /**
     * Récupère le container existant pour la position spécifiée, le crée s'il n'existe pas.
     * @param position - Position du toast ('top right' | 'top left' | 'bottom right' | 'bottom left').
     * @returns Le container HTML du toast.
     */
    private static getOrCreateContainer(position: EsToastConfig["position"]) {
        let container = EsToast.getContainer(position);
        if (!container) {
            container = document.createElement("div");
            container.classList.add("es_toast-container", "py-1");
            container.dataset.position = position;
            document.body.appendChild(container);
        }

        return container;
    }
}
