import { EsObservable, EsSubscription } from "./Observable";

export type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

/**
 * La classe `Breakpoints` fournit des utilitaires pour gérer les points de rupture (breakpoints) de l'application.
 */
export class Breakpoints {
    private static observable: EsObservable<Breakpoint>;

    /**
     * Les points de rupture définis avec leur valeur respective en pixels.
     */
    public static values: { [key in Breakpoint]: number } = {
        xs: 0,
        sm: 480,
        md: 640,
        lg: 750,
        xl: 1020,
        xxl: 1200
    };

    private constructor() { }

    /**
     * Obtient le point de rupture actuel en fonction de la largeur de la fenêtre.
     *
     * @returns {Breakpoint} Le point de rupture actuel.
     */
    public static get current(): Breakpoint {
        const windowWidth = window.innerWidth;

        for (const [key, value] of this.sortedBreakpoints) {
            if (windowWidth >= value) {
                return key as Breakpoint;
            }
        }

        return 'xxl';
    }

    /**
     * Trie les points de rupture du plus grand au plus petit.
     */
    private static get sortedBreakpoints() {
        return Object.entries(this.values).sort((a, b) => b[1] - a[1]);
    }

    /**
     * Retourne la valeur en pixels d'un point de rupture spécifique.
     *
     * @param {Breakpoint} breakpoint - Le point de rupture dont la valeur est demandée.
     * @returns {number} La valeur en pixels du point de rupture.
     */
    public static get(breakpoint: Breakpoint): number {
        return this.values[breakpoint];
    }

    /**
     * Retourne la valeur en pixels du point de rupture supérieur suivant.
     *
     * @param {Breakpoint} breakpoint - Le point de rupture de référence.
     * @returns {number} La valeur en pixels du point de rupture supérieur suivant ou Infinity si aucun n'est trouvé.
     */
    public static getUp(breakpoint: Breakpoint): number {
        if (this.current) {
            const bkpArr = Object.keys(this.values);
            const nextBkp = bkpArr[bkpArr.findIndex(bkp => bkp == breakpoint) + 1] as Breakpoint;
            return this.values[nextBkp] ?? Infinity;
        }

        return Infinity;
    }

    /**
     * Souscrit à des changements de points de rupture.
     *
     * @param {Function} subscriber - La fonction de rappel appelée lorsqu'un changement de point de rupture se produit.
     * @returns {EsSubscription} L'abonnement à la source d'observation.
     */
    public static subscribe(subscriber: (value: Breakpoint) => any): EsSubscription {
        if (!this.observable) {
            this.observable = new EsObservable<Breakpoint>(this.current);
            let lastValue = this.current;
            window.addEventListener('resize', () => {
                const current = this.current;
                if (lastValue !== current) {
                    this.observable.next(current);
                    lastValue = current;
                }
            });
        }

        return this.observable.subscribe(subscriber);
    }
}
