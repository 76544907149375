import { Transitions } from "../es_tools";

/**
 * L'interface `EsCollapseElement` étend l'élément HTMLElement et ajoute une propriété `esCollapse`.
 */
export interface EsCollapseElement extends HTMLElement {
    esCollapse?: EsCollapse;
}

/**
 * Options de configuration pour le composant `EsCollapse`.
 * @interface
 */
export interface EsCollapseOptions {
    /**
     * Élément parent auquel attacher le composant `EsCollapse`.
     * Si le parent est spécifié, alors tous les éléments rétractables sous le parent spécifié seront fermés lorsque cet élément rétractable est affiché (similaire au comportement traditionnel de l'accordéon).
     * @defaultValue `undefined`
     */
    parent?: string | HTMLElement;
}

/**
 * La classe `EsCollapse` représente un composant de collapse (effondrement) utilisé pour afficher/masquer le contenu.
 */
export class EsCollapse {
    /** Élément HTML associé à l'instance de collapse. */
    private collapseElement: EsCollapseElement;
    /**
     * Durée de transition en millisecondes pour l'animation du collapse.
     * @private
     * @defaultValue 600
     */
    private transitionDuration: number = 600;

    /**
     * Options de configuration pour le composant `EsCollapse`.
     * @public
     */
    options?: EsCollapseOptions;

    /**
     * Constructeur de la classe `EsCollapse`.
     * @param collapseElement L'élément HTML associé au collapse.
     */
    private constructor(collapseElement: HTMLElement, options?: EsCollapseOptions) {
        this.options = options;
        this.collapseElement = collapseElement;
        this.collapseElement.esCollapse = this;
    }

    /**
     * Récupère les éléments déclencheurs du collapse.
     * @returns Un tableau d'éléments HTML déclencheurs.
     */
    public get triggerElements() {
        const results: HTMLElement[] = [];

        document.querySelectorAll<HTMLElement | HTMLAnchorElement>('[data-toggle="collapse"]').forEach(toggleElement => {
            const targetSelector = toggleElement.dataset.target || toggleElement.getAttribute('href');
            if (targetSelector) {
                const targetElements = document.querySelectorAll(targetSelector);
                targetElements.forEach(targetElement => {
                    if (targetElement === this.collapseElement) {
                        results.push(toggleElement);
                    }
                });
            }
        });

        return results;
    }

    /**
     * Indique si le collapse est actuellement réduit.
     */
    public get collapsed() {
        return !(this.collapseElement.classList.contains('show') || this.collapsing);
    }

    /**
     * Indique si le collapse est actuellement en train de se réduire.
     */
    private get collapsing() {
        return this.collapseElement.classList.contains('collapsing');
    }

    /**
     * Indique si le collapse est actuellement étendu.
     */
    public get expanded() {
        return !this.collapsed;
    }

    /**
     * Indique si le collapse fait partie d'un accordéon.
     * @private
     */
    private get isAccordion() {
        return !!(this.collapseElement.dataset.parent || this.options?.parent);
    }

    /**
     * Obtient une instance de `EsCollapse` associée à un élément de collapse.
     * @param collapseElement L'élément de collapse.
     * @returns L'instance de `EsCollapse`.
     */
    public static getInstance(collapseElement: EsCollapseElement) {
        return collapseElement.esCollapse;
    }

    /**
     * Obtient ou crée une instance de `EsCollapse` associée à un élément de collapse.
     * @param collapseElement L'élément de collapse.
     * @returns L'instance de `EsCollapse`.
     */
    public static getOrCreateInstance(collapseElement: EsCollapseElement) {
        return collapseElement.esCollapse || new EsCollapse(collapseElement);
    }

    /**
     * Libère les ressources associées à l'instance de `EsCollapse`.
     */
    public dispose() {
        delete this.collapseElement.esCollapse;
    }

    /**
     * Masque le contenu du collapse s'il est actuellement étendu.
     */
    public hide() {
        if (this.expanded && !this.collapsing) {
            let transitionCompleted: boolean = false;
            // Émet un événement indiquant que le collapse est en train de se masquer.
            this.dispatchEvent('hide');

            // WAI
            this.triggerElements.forEach(element => {
                element.setAttribute('aria-expanded', 'false');
            });

            // Réserve l'espace nécessaire pour le contenu pendant l'animation de réduction.
            this.collapseElement.style.height = this.collapseElement.getBoundingClientRect().height + "px";

            const setTransitionEnd = () => {
                if (transitionCompleted) {
                    return;
                }
                this.collapseElement.classList.remove('collapsing');
                this.collapseElement.classList.add('collapse');

                // Émet un événement indiquant que le collapse est maintenant masqué.
                this.dispatchEvent('hidden');
                transitionCompleted = true;
            };

            // Utilise requestAnimationFrame pour retarder l'exécution de l'animation.
            requestAnimationFrame(() => {
                Transitions.onTransition(this.collapseElement, "transitionend", {
                    before: () => {

                        this.collapseElement.classList.add('collapsing');
                        this.collapseElement.classList.remove('collapse');
                        this.collapseElement.classList.remove('show');

                        // Réduit la hauteur du collapse à 0.
                        this.collapseElement.style.height = "0";
                    },
                    on: () => {
                        setTransitionEnd();
                    }
                });


                // Sécuriser la transition de fin
                setTimeout(() => {
                    setTransitionEnd();
                }, this.transitionDuration);
            });
        }
    }

    /**
     * Affiche le contenu du collapse s'il est actuellement réduit.
     */
    public show() {
        if (this.collapsed && !this.collapsing) {
            let transitionCompleted: boolean = false;
            // Émet un événement indiquant que le collapse est en train de se montrer.
            this.dispatchEvent('show');

            // WAI
            this.triggerElements.forEach(element => {
                element.setAttribute('aria-expanded', 'true');
            });

            const setTransitionEnd = () => {
                if (transitionCompleted) {
                    return;
                }
                this.collapseElement.classList.remove('collapsing');
                this.collapseElement.classList.add('collapse', 'show');
                this.collapseElement.style.height = "";

                // Émet un événement indiquant que le collapse est maintenant visible.
                this.dispatchEvent('shown');
                transitionCompleted = true;
            };

            // Utilise requestAnimationFrame pour retarder l'exécution de l'animation.
            requestAnimationFrame(() => {
                Transitions.onTransition(this.collapseElement, "transitionend", {
                    before: () => {
                        this.collapseElement.classList.remove('collapse');
                        this.collapseElement.classList.add('collapsing');

                        // Ajuste la hauteur du collapse pour afficher tout le contenu.
                        this.collapseElement.style.height = this.collapseElement.scrollHeight + "px";
                    },
                    on: () => {
                        setTransitionEnd();
                    }
                });



                // Sécuriser la transition de fin
                setTimeout(() => {
                    setTransitionEnd();
                }, this.transitionDuration);
            });

            // Accordion
            if (this.isAccordion) {
                const parentElement = this.getParentAccordion();
                if (parentElement) {
                    parentElement.querySelectorAll<HTMLElement>(`[data-parent="#${parentElement.id}"]`).forEach(collapseElement => {
                        if (collapseElement !== this.collapseElement) {
                            EsCollapse.getOrCreateInstance(collapseElement).hide();
                        }
                    });
                }
            }
        }
    }

    /**
     * Alterne l'état du collapse entre visible et masqué.
     */
    public toggle() {
        if (this.collapsed) {
            this.show();
        } else {
            this.hide();
        }
    }

    /**
     * Émet un événement personnalisé indiquant un changement d'état du collapse.
     * @param eventName Le nom de l'événement.
     */
    private dispatchEvent(eventName: string) {
        this.collapseElement.dispatchEvent(new CustomEvent(`${eventName}.es.collapse`));
        this.collapseElement.dispatchEvent(new CustomEvent(`${eventName}.bs.collapse`));
    }

    /**
     * Obtient l'élément parent de l'accordéon associé à l'instance de collapse.
     * @private
     * @returns L'élément parent de l'accordéon ou null s'il n'est pas défini.
     */
    private getParentAccordion() {
        const parent = this.collapseElement.dataset.parent || this.options?.parent;
        if (!parent) {
            return null;
        }

        if (parent instanceof HTMLElement) {
            return parent;
        } else {
            return this.collapseElement.closest<HTMLElement>(parent);
        }
    }
}
