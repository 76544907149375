export class Transitions {
    private static get animated() {
        // Vérifie si le média prefers-reduced-motion est activé
        const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

        return !prefersReducedMotion;
    }


    public static onTransition(element: HTMLElement, type: "transitionend" | "transitionstart" | "transitioncancel" | "transitionrun", config: {
        before?: () => any,
        after?: () => any,
        on?: () => any;
    }) {

        const onEnd = () => {
            if (config.on) {
                config.on();
            }

            if (config.after) {
                config.after();
            }
        };
        if (config.before) {
            config.before();
        }
        if (this.animated) {
            element.addEventListener(type, onEnd, { once: true });
        } else {
            onEnd();
        }
    }
}